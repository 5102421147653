<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        dark
        color="info"
      >
        <v-toolbar-title>
          Pesquisar
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <v-row>
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="month"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formatedMonth"
                  label="Mês"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="month"
                type="month"
                no-title
                scrollable
                @change="$refs.menu.save(month)"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-select
              v-model="typeOfApportionmentId"
              :items="typesOfApportionments"
              item-text="description"
              item-value="id"
              label="Tipo"
              hide-details
              clearable
              :loading="loadingTypesOfApportionments"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="info"
          outlined
          @click="filter"
        >
          <v-icon
            left
            v-text="'mdi-filter'"
          />
          Pesquisar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import typesOfApportionmentsApi from '@/api/types-of-apportionments'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        menu: false,
        loadingTypesOfApportionments: false,
        typesOfApportionments: [],
        month: '',
        typeOfApportionmentId: null,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },

      formatedMonth: {
        get () {
          if (!this.month) {
            return ''
          }

          const parts = this.month.split('-')
          return `${parts[1]}/${parts[0]}`
        },
        set (value) {
          if (!value) {
            this.month = ''
            return
          }

          const parts = value.split('/')
          this.month = `${parts[0]}-${parts[1]}`
        }
      }
    },

    mounted () {
      this.loadTypesOfApportionments()
    },

    methods: {
      async loadTypesOfApportionments () {
        try {
          this.loadingTypesOfApportionments = true

          const responseTypesOfApportionments = await typesOfApportionmentsApi.list()

          this.typesOfApportionments = responseTypesOfApportionments.data.typesOfApportionments
        } catch (e) {
          console.error(e)
        } finally {
          this.loadingTypesOfApportionments = false
        }
      },

      filter () {
        const data = { }

        if (this.month) {
          data.month = this.month.split('-')[1]
          data.year = this.month.split('-')[0]
        }

        if (this.typeOfApportionmentId) {
          data.typeOfApportionmentId = this.typeOfApportionmentId
        }

        this.$emit('filter', data)
      },
    },

  }
</script>
